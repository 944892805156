import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const ThankYouPage = () => (
  <Layout>
    <Seo title="Thank You" />
    <article className="article flow">
      <div className="hero-image-wrapper">
        <StaticImage
          className="hero-image"
          src="../images/main-home.jpg"
          width={637}
          height={637}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="a hand full of dirt"
        />
      </div>
      <div className="headline">
        <h1>Thank You</h1>
      </div>
      <div className="article-wrapper flow">
        <p>
          Thank you for completing our survey! A Carbon Cropping Specialist will review your info and reach out via your preferred method of communication&nbsp;soon.
        </p>
      </div>
    </article>
  </Layout>
);

export default ThankYouPage;
